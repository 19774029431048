<template>
    <SetupGlobal @pressed="getStarted" buttonText="LET'S GET STARTED">
        <edit-amenity-drawer />
        <Popup
            v-if="popupBoolean"
            :skipIntroScreen="true"
            @close="popupBoolean = false"
            @done="finished"
            introImage="https://bildhive.nyc3.digitaloceanspaces.com/noinstace/5ab8ea7b924049d1a30c1399597fdd8a.png"
            color="purple"
            :step="steps[currentStep]"
            v-model="step"
            title="Headline goes here"
            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ac erat gravida, tempor magna quis, lacinia ex. Pellentesque ac ex aliquam dui feugiat consequat. Morbi id dolor enim. Mauris dictum ligula"
        >
            <template v-slot:right>
                <component
                    :is="'Step' + currentStep"
                    @next="next"
                    @done="finished"
                    @load="(is) => (loadPopup = is ? is : !loadPopup)"
                />
            </template>
        </Popup>
    </SetupGlobal>
</template>

<script>
import SetupGlobal from "bh-mod/components/layout/SetupGlobal";
import Popup from "bh-mod/components/layout/SetupGlobal/Popup";
import BhLoading from "bh-mod/components/common/Loading";
import EditAmenityDrawer from "@/components/amenities/EditDrawer";
import Step0 from "./Step0";
import Step1 from "./Step1";

export default {
    components: {
        SetupGlobal,
        Popup,
        Step0,
        BhLoading,
        Step1,
        EditAmenityDrawer,
    },
    data() {
        return {
            amenityOBJ: {
                name: "",
                lat: null,
                lng: null,
                logo: "",
                theme: "",
                categories: [],
            },
            loadPopup: false,
            popupBoolean: false,
            currentStep: 0,
            step: 0,
            steps: [
                {
                    title: "Create your amenity map style",
                    backButton: true,
                },
                {
                    title: "What do you need on your amenity map?",
                    backButton: false,
                },
            ],
        };
    },
    watch: {
        step(val) {
            if (this.steps[val]) {
                this.currentStep = val;
            } else {
                this.finished();
            }
        },
    },
    computed: {
        instance() {
            return this.$store.state.instance;
        },
        theApp() {
            return this.$store.state.theApp;
        },
    },
    methods: {
        next(data) {
            if (this.currentStep === 0) {
                this.amenityOBJ = data;
                this.$api
                    .post(`/amenities/${this.instance.id}`, this.amenityOBJ)
                    .then(({ data }) => {
                        this.$store.dispatch("SET_APPDATA", data);
                    })
                    .catch((err) => {
                        let error = this.$err(err);
                        this.$message.error(error);
                    });
                this.step++;
            }
        },
        finished() {
            this.$store.commit("LOAD", true);
            this.$router.push("/");
        },
        getStarted() {
            this.popupBoolean = true;
        },
    },
    created() {},
};
</script>

<style lang="scss">
#map {
    width: 100%;
    height: 450px;
}

.center-search-dialog {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    transform-origin: center bottom;
    transition: max-height 0.3s ease-out;
}
.amenity-dialog {
    [role="tablist"] {
        display: none;
    }
}

.modal-loading-mask {
    position: absolute;
    left: 0;
    width: 100%;
    top: 0;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    z-index: 10;
    display: flex;
    place-content: center;
    place-items: center;
    font-size: 40px;
    opacity: 0;
    transition: opacity 0.3s ease-out;
    pointer-events: none;
    &.modal-loading {
        opacity: 1;
        pointer-events: all;
    }
}
</style>

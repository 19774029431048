<template>
    <div>
        <h1>Setting up your development location</h1>

        <div class="aC pb-2 pt-2 bg-white">
            <a-form-model-item label="Development Name">
                <a-input size="large" v-model="amenityOBJ.name" placeholder="Enter your Development Name" >
                </a-input>
            </a-form-model-item>
        </div>

        <div>Type full address including city below to search and place your development marker</div>

        <div class="aC pb-4 pt-2 bg-white">
            <div class="dF aC w-full">
                <vue-google-autocomplete
                    id="map2"
                    classname="form-control f1"
                    placeholder="Start typing to enter Development Address"
                    v-on:placechanged="getAddressData"
                >
                </vue-google-autocomplete>
            </div>
        </div>
        <div id="map"></div>

        <div class="mt-5 dF aC">
            <h4>Choose marker image for your community</h4>
            <a-tooltip class="ml-2" overlayClassName="change-tooltip-color">
                <template slot="title">
                    For optimal resolution size to 300px x 300px. Save your file as either a PNG or JPEG no larger than 200KB
                </template>
                <a-icon type="question-circle" style="line-height: 25px; color: var(--orange);" />
            </a-tooltip>
        </div>

        <div class="relative mt-3">
            <!-- <div @click="$store.commit('MEDIA_SELECT',{callback:selectedMarkerIcon,type:'images'})" class="edit-current-marker2"><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg></div>  -->
            <div @click="$store.commit('MEDIA_SELECT',{callback:selectedMarkerIcon,type:'images'})" style="top: 3px;" class="edit-current-marker2"><i aria-label="icon: edit" class="anticon anticon-edit"><svg viewBox="64 64 896 896" data-icon="edit" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false" class=""><path d="M257.7 752c2 0 4-.2 6-.5L431.9 722c2-.4 3.9-1.3 5.3-2.8l423.9-423.9a9.96 9.96 0 0 0 0-14.1L694.9 114.9c-1.9-1.9-4.4-2.9-7.1-2.9s-5.2 1-7.1 2.9L256.8 538.8c-1.5 1.5-2.4 3.3-2.8 5.3l-29.5 168.2a33.5 33.5 0 0 0 9.4 29.8c6.6 6.4 14.9 9.9 23.8 9.9zm67.4-174.4L687.8 215l73.3 73.3-362.7 362.6-88.9 15.7 15.6-89zM880 836H144c-17.7 0-32 14.3-32 32v36c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-36c0-17.7-14.3-32-32-32z"></path></svg></i></div>
            <marker-selector @callback="updateMarkerIcon">
                <div class="image-holder-box">
                    <span class="content">
                    <div class="inside" :style="{ 'background-image': 'url(' + amenityOBJ.logo + ')' }" />
                    </span>
                </div>
            </marker-selector>
        </div>

        <h4 class="mt-5">Map theme color</h4>
        <a-checkbox value="1" @change="onChange" defaultChecked :checked="checkedList.includes('1')" class="theme-check-box mr-3">
            <div class="theme-holder-box" style="position: relative; top: -14px; background-image: url('http://basemaps.cartocdn.com/rastertiles/voyager/1/0/0.png');" />
            Default
        </a-checkbox>

        <a-checkbox value="2" @change="onChange" :checked="checkedList.includes('2')" class="theme-check-box mr-3">
            <div class="theme-holder-box" style="position: relative; top: -14px; background-image: url('http://basemaps.cartocdn.com/light_all/1/0/0.png');" />
            Light
        </a-checkbox>

        <a-checkbox value="3" @change="onChange" :checked="checkedList.includes('3')" class="theme-check-box mr-3">
            <div class="theme-holder-box" style="position: relative; top: -14px; background-image: url('http://basemaps.cartocdn.com/dark_all/1/0/0.png');" />
            Dark
        </a-checkbox>

        <a-checkbox value="4" @change="onChange" :checked="checkedList.includes('4')" class="theme-check-box">
            <div class="theme-holder-box" style="position: relative; top: -14px; background-image: url('http://basemaps.cartocdn.com/rastertiles/voyager/1/0/0.png');" />
            Custom
        </a-checkbox>

        <div v-if="checkedList.includes('4')" class="mt-5">
            <h5 class="mb-3">Choose a color</h5>
            <a-radio-group @change="onAfterChange" button-style="solid">
                <a-radio-button :value="60">
                    Green
                </a-radio-button>
                <a-radio-button :value="130">
                    Blue
                </a-radio-button>
                <a-radio-button :value="270">
                    Red
                </a-radio-button>
            </a-radio-group>
        </div>

        <!-- <div v-if="checkedList.includes('4')" class="dF mt-5">
            <a-slider :default-value="0" :min="0" :max="360" style="width: 75%;" @afterChange="onAfterChange" />
            <a-button @click="updateCSS">Update</a-button>
        </div> -->

        <portal to="destination">
            <!-- <a-button size="large" class="text-black mx-4" type='link' @click="skip">SKIP</a-button> -->
            <a-button size="large" type='purple' :disabled="amenityOBJ.lat === null" class="back-button" @click="next" >NEXT</a-button>
        </portal>

    </div>
</template>

<script>
import * as maptalks from 'maptalks'
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import MarkerSelector from '@/components/amenities/MarkerSelector'
import { gsap } from "gsap";

export default {
    props:{
        value:Number,
    },
    components:{VueGoogleAutocomplete, MarkerSelector},
    data:() => ({
        tempCSS:'',
        currentStep:0,
        amenityCats:[],
        map:null,
        checkedList:['1'],
        theme:'',
        selectAddress:false,
        center:[-79.379041,43.815641],
        point:null,
        infoWindow:null,
        vectorLayer:null,
        amenityOBJ:{
          name:'',
          lat:null,
          lng:null,
          logo:'https://bildhive.nyc3.digitaloceanspaces.com/96c8782107aa4021b5bb02f4f50c2aa6.png',
          theme:{
              type:'',
              cssFilter:0,
          },
          categories:[

          ]
        },
    }),
    watch:{
        currentStep(val){
            return this.$emit('input',val)
        },
        theme(val){
            console.log('NEW AMENITYOBJ THEME', this.amenityOBJ.theme.type)
            this.tempCSS = 0
            if (val && this.theme.substring(0,1) != '2'){
                let self = this
                setTimeout(() => {

                self.map = new maptalks.Map('map', {
                    center : self.center,
                    zoom: 12,
                    dragPitch: true,
                    dragRotate: true,
                    minZoom: 12,
                    maxZoom: 16,
                    dragRotatePitch: true,
                    baseLayer: new maptalks.TileLayer('base', {
                        urlTemplate: self.theme,
                        subdomains: ['a', 'b', 'c', 'd'],
                        attribution: '&copy; <a href="http://osm.org">OpenStreetMap</a> contributors, &copy; <a href="https://carto.com/">CARTO</a>'
                    })
                })
                }, 200);
            }
            else if (val && this.theme.substring(0,1) == '2'){
                let self = this
                setTimeout(() => {

                self.map = new maptalks.Map('map', {
                    center : self.center,
                    zoom: 12,
                    dragPitch: true,
                    dragRotate: true,
                    minZoom: 12,
                    maxZoom: 16,
                    dragRotatePitch: true,
                    baseLayer: new maptalks.TileLayer('base', {
                        urlTemplate: self.theme.substring(1),
                        subdomains: ['a', 'b', 'c', 'd'],
                        attribution: '&copy; <a href="http://osm.org">OpenStreetMap</a> contributors, &copy; <a href="https://carto.com/">CARTO</a>'
                    })
                })
                }, 100);
            }
            if (this.amenityOBJ.lat !== null && this.amenityOBJ.lng !== null){
                setTimeout(() => {
                    this.putMarker(this.amenityOBJ.lat,this.amenityOBJ.lng)
                    this.changeView(this.amenityOBJ.lat,this.amenityOBJ.lng)
                }, 200);
            }
        },
    },
    computed: {
        instance(){
            return this.$store.state.instance
        },
        theApp(){
            return this.$store.state.theApp
        },
    },
    methods:{
        onChange(e) {
            if (!this.checkedList.includes(e.target.value)){
                this.checkedList = [e.target.value]
				if(this.map){
					this.map.remove()
				}
                if (e.target.value == '1'){
                    this.theme = 'http://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png'
                    this.amenityOBJ.theme.type = 'rastertiles/voyager'
                    this.amenityOBJ.theme.cssFilter = 0
                } else if (e.target.value == '2'){
                    this.theme = 'http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png'
                    this.amenityOBJ.theme.type = 'light_all'
                    this.amenityOBJ.theme.cssFilter = 0
                } else if (e.target.value == '3'){
                    this.theme = 'http://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png'
                    this.amenityOBJ.theme.type = 'dark_all'
                    this.amenityOBJ.theme.cssFilter = 0
                } else {
                    this.theme = '2http://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png'
                    this.amenityOBJ.theme.type = 'rastertiles/voyager'
                    this.amenityOBJ.theme.cssFilter = 0
                }
            }

        },
        updateCSS(){
            console.log('UPDATE CSS CLICKED', this.tempCSS)

            if (this.amenityOBJ.theme.cssFilter != this.tempCSS){
                this.amenityOBJ.theme.cssFilter = this.tempCSS
                this.map.remove()
                let self = this
                setTimeout(() => {

                self.map = new maptalks.Map('map', {
                    center : self.center,
                    zoom: 12,
                    dragPitch: true,
                    dragRotate: true,
                    minZoom: 12,
                    maxZoom: 16,
                    dragRotatePitch: true,
                    baseLayer: new maptalks.TileLayer('base', {
                        urlTemplate: self.theme.substring(1),
                        subdomains: ['a', 'b', 'c', 'd'],
                        attribution: '&copy; <a href="http://osm.org">OpenStreetMap</a> contributors, &copy; <a href="https://carto.com/">CARTO</a>',
                        cssFilter : `hue-rotate(${this.tempCSS}deg)`
                    })
                })
                }, 200);

                if (this.amenityOBJ.lat !== null && this.amenityOBJ.lng !== null){
                    setTimeout(() => {
                        this.putMarker(this.amenityOBJ.lat,this.amenityOBJ.lng)
                        this.changeView(this.amenityOBJ.lat,this.amenityOBJ.lng)
                    }, 200);
                }
            }
        },
        onAfterChange(e) {
            console.log('afterChange: ', e.target.value);
            this.tempCSS = e.target.value
            this.updateCSS()
        },
        selectedMarkerIcon(url){
            this.amenityOBJ.logo = url.url
        },
        updateMarkerIcon(data){
            this.amenityOBJ.logo = data
        },
        skip(){
            return this.$emit('next')
        },
        next(){
            return this.$emit('next', this.amenityOBJ )
        },

        changeView(latitude,longitude) {
            this.map.animateTo({
            center: [longitude, latitude],
            zoom: 15,
            pitch: 0,
            bearing: 20
            }, {
            duration: 1000
            });
            // setTimeout(() => {

            // }, 4000);
        },
        putInfo(center){
            var layer = new maptalks.VectorLayer('vector').addTo(map);
            var marker = new maptalks.Marker([-0.113049,51.49856]).addTo(layer);

            marker.setInfoWindow({
                'title'     : 'Marker\'s InfoWindow',
                'content'   : 'Click on marker to open.'

                // 'autoPan': true,
                // 'width': 300,
                // 'minHeight': 120,
                // 'custom': false,
                //'autoOpenOn' : 'click',  //set to null if not to open when clicking on marker
                //'autoCloseOn' : 'click'
            });

            marker.openInfoWindow();
        },
        putMarker(latitude,longitude){

            if (this.vectorLayer) this.map.removeLayer(this.vectorLayer);

            // same point without altitude
            // var point0 = new maptalks.Marker(
            //   [longitude, latitude],
            // ).updateSymbol({
            //   markerOpacity : 0.5,
            //   markerFill : '#bbb'
            // });

            let self = this
            this.point = new maptalks.Marker(
                [longitude, latitude],
                {
                    draggable : true,
                    properties : {
                    altitude : 5
                    }
                }
            );

            this.vectorLayer = new maptalks.VectorLayer('vector',{
                enableAltitude : true,        // enable altitude
                altitudeProperty : 'altitude' // altitude property in properties, default by 'altitude'
            }).addTo(self.map);

            this.point.addTo(this.vectorLayer);

            this.point.setInfoWindow({
                //'autoOpenOn' : 'click',  //set to null if not to open window when clicking on map
                'single' : false,
                'width'  : 183,
                'height' : 105,
                'custom' : true,
                'dx' : -3,
                'dy' : -12,
                'content'   : '<div class="pop__content">' +
                '<div class="pop__title">Lat/Lng Info </div>' +
                `<div class="pop__time popup-lat-lng"> <span id="popup-lat">${latitude}</span> <br/><span id="popup-lng">${longitude}</span></div>` +
                '</div>'
            });
            this.amenityOBJ.lat = latitude
            this.amenityOBJ.lng = longitude

            this.point.openInfoWindow();


            // this.point.on('mouseenter', function (e) {
            //   console.log('e',e)
            // })
            this.point.on('dragend', function (e) {
                console.log('e',e)
                self.amenityOBJ.lat = e.coordinate.y
                self.amenityOBJ.lng = e.coordinate.x

                // TweenLite.to(Cont,5,{val:NewVal,roundProps:"val",onUpdate:function(){
                //   document.getElementById("counter").innerHTML=Cont.val
                // }});
                // self.location.lat = e.coordinate.y
                // self.location.lng = e.coordinate.x

                if (document.querySelector('.popup-lat-lng')) {
                document.getElementById("popup-lat").innerHTML=self.amenityOBJ.lat
                document.getElementById("popup-lng").innerHTML=self.amenityOBJ.lng
                gsap.to(self.amenityOBJ.lat,{duration:2,val:e.coordinate.y,onUpdate:function(){
                    document.getElementById("popup-lat").innerHTML=self.amenityOBJ.lat
                }})
                gsap.to(self.amenityOBJ.lng,{duration:2,val:e.coordinate.x,onUpdate:function(){
                    document.getElementById("popup-lng").innerHTML=self.amenityOBJ.lng
                }})
                }


            })
        },
        getAddressData({latitude,longitude,locality}){
            this.changeView(latitude,longitude)
            this.putMarker(latitude,longitude)
            // this.putInfo(latitude,longitude)

        },

    },
    created(){
        this.amenityOBJ.theme.type = 'rastertiles/voyager'
        this.theme = 'http://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png'
    }
}
</script>

<style scoped>
    .theme-holder-box{
		display: table;
		width: 116px;
		height: 116px;
		text-align: center;
		vertical-align: top;
		border: 1px solid #d8d7d7;
		background-color: #F7F5F9;
		cursor: pointer;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 50%;
	}
    .theme-holder-box:hover {
        border-color: #9693E7;
        transition: border-color .3s ease;
    }
    .image-holder-box{
      display: table;
      width: 104px;
      height: 104px;
      text-align: center;
      vertical-align: top;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      cursor: pointer;
  }
  .image-holder-box:hover{
      border-color: #9693E7;
      transition: border-color .3s ease;
  }
  .image-holder-box .content{
    display: table-cell;
    width: 100%;
    height: 100%;
    padding: 8px;
    text-align: center;
    vertical-align: middle;
  }
  .image-holder-box .content .inside{
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50%;
    width: 100px;
    height: 100px;
  }
  .image-selector {
	position: absolute;
	z-index: 1000;
	width: 250px;
	margin-top: 75px;
  }

</style>

<style lang="scss">
    .edit-current-marker2{
        i{
            pointer-events: none;
        }
        svg{
            pointer-events: none;
        }
        cursor: pointer;
        position: absolute;
        z-index: 100;
        left: 80px;
        top: 0;
        right: 0;
        background: white;
        padding: 10px;
        transform: translate(50%,-50%);
        border-radius: 50%;
        box-shadow: -1px 1px 4px rgba(0,0,0,0.3);
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover{
            background:white;
            color:var(--med-gray);
        }
    }
    .theme-check-box{
        &:hover{
            .ant-checkbox-inner{
                border-color: #9693E7;
            }
            .ant-checkbox-checked{
                border-color: #9693E7;
            }
            .ant-checkbox-checked:after{
                border-color: #9693E7;
            }
        }
    }
    .theme-check-box .ant-checkbox {
        float: right;
        right: -5px;
        z-index: 100;
        &:hover{
            .ant-checkbox-inner{
                border-color: #9693E7;
            }
            .ant-checkbox-checked{
                border-color: #9693E7;
            }
            .ant-checkbox-checked:after{
                border-color: #9693E7;
            }
        }
    }
    .theme-check-box .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #9693E7;
        border-color: #9693E7;
    }
    .theme-check-box .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: #9693E7;
    }
    .change-tooltip-color .ant-tooltip-content .ant-tooltip-inner{
      background:#3F3356;
  }
</style>

<style lang="scss">
    #map{
    width:100%;
    height:450px;
  }

  .center-search-dialog{
    position: absolute;
    width:100%;
    left:0;
    bottom:0;
    transform-origin: center bottom;
    transition:max-height .3s ease-out;

  }
  .amenity-dialog {
    [role="tablist"]{
      display:none;
    }
    // .ant-modal-body{
    //   min-height:300px
    // }
  }
  .modal-loading-mask{
    position:absolute;
    left:0;
    width:100%;
    top:0;
    height:100%;
    background:rgba(255,255,255,0.5);
    z-index:10;
    display: flex;
    place-content: center;
    place-items: center;
    font-size:40px;
    opacity:0;
    transition:opacity .3s ease-out;
    pointer-events: none;
    &.modal-loading{
      opacity:1;
      pointer-events: all;
    }
  }
</style>

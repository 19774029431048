<template>
  <a-drawer
      :zIndex="1000"
      :title="title"
      :width="'650px'"
      @close="onClose"
      :visible="showAddCategory"
      :drawerStyle="{height: 'calc(100% - 108px)',overflow: 'auto'}"
    >
      <bh-loading :show="loading" />

				<a-form-model ref="category" :model="category" >
					<a-row :gutter="16">
						<a-col :span="24">
						<a-form-model-item required prop="label" label="Category Title" :rules="req('Please enter the Category Title')">
							<a-input
								v-model="category.label"
								placeholder="Enter category name"
								required
							>
							</a-input>
						</a-form-model-item>
						</a-col>
            <a-col :span="24">
              <a-form-model-item prop="description" label="Description">
                <a-textarea placeholder="Please enter a description about this Category. This could help in publishing your amenities in different ways." :rows="4" v-model="category.description">
                </a-textarea>
              </a-form-model-item>
            </a-col>
					</a-row>
				</a-form-model>
					<a-row :gutter="16">
						<a-col :span="12">
							<a-form-model-item>
                <div @click="$store.commit('MEDIA_SELECT',{callback:selectedImg,type:'images'})" style="top: 30px;" class="edit-current-marker"><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg></div>
								<div class="dF aC" style="line-height: 2;">
                  Marker Icon
                  <a-tooltip class="ml-1" overlayClassName="change-tooltip-color">
                      <template slot="title">
                          For optimal resolution size to 300px x 300px. Save your file as either a PNG or JPEG no larger than 200KB
                      </template>
                      <a-icon type="question-circle" style="line-height: 25px; color: var(--orange);" />
                  </a-tooltip>
                </div>
                <marker-selector @callback="updateCatIcon">
								<div class="image-holder-box">
									<span class="content">
									<div class="inside" :style="{ 'background-image': 'url(' + category.icon + ')' }" />
									</span>
								</div>
								</marker-selector>

							</a-form-model-item>
						</a-col>
					</a-row>

					<a-row :gutter="16">
						<a-col :span="12">
							<h5>Types</h5>
							<p>Sourced from Google</p>
						</a-col>
					</a-row>
					<a-checkbox-group v-if="showAddCategory" @change="selectedCategories">
						<a-row type="flex">
              <a-col class="mb-4" :span="8" v-for="(cat,catI) in allCats" :key="cat+catI">
                <div style="font-weight: 600;">{{catI}}</div>
                <template v-for="(aCat,aCatI) in cat">
                  <a-checkbox class="w-full" style="margin-left: 0;" :value="aCat" :key="aCat+aCatI">
                    {{aCat[0].toUpperCase() + aCat.split('_').join(' ').slice(1)}}
                  </a-checkbox>
                </template>
							</a-col>
						</a-row>
					</a-checkbox-group>

	  <form-submit @submit="submitNew" @cancel="onClose">
	  </form-submit>
    </a-drawer>
</template>

<script>
  import {markerIcon} from 'bh-mod'
  import bhLoading from 'bh-mod/components/common/Loading'
  import MarkerSelector from '@/components/amenities/MarkerSelector'
  import FormSubmit from '@/components/amenities/FormSubmit'
  export default {
    components:{bhLoading,MarkerSelector,FormSubmit},
    props: {
		title:{
			default:'',
			type:String
        },
    },
    data() {
      return {
        chosenGCat:{},
        chosenGoogleCat:null,
        loading:false,
        // form: this.$form.createForm(this),
        googleQuery:'',
        dataSource:[],
        processingGoogle:false,
        placeChosen:false,
        category:{
          label:'',
          icon:markerIcon,
          description:'',
        },
		chosenCategories:[
			{
				label:'',
			},
		],
		allCats:{
          "Commute":[
            'airport',
            'bus_station',
            'light_rail_station',
            'subway_station',
            'taxi_stand',
            'train_station',
            'transit_station'
          ],
          "Food & Drink":[
            'bakery',
            'bar',
            'cafe',
            'liquor_store',
            'meal_delivery',
            'meal_takeaway',
            'restaurant',
            'supermarket'
          ],
          "Health & Personal Care":[
            'beauty_salon',
            'dentist',
            'doctor',
            'gym',
            'hair_care',
            'hospital',
            'pharmacy',
            'physiotherapist',
            'spa',
            'veterinary_care'
          ],
          "Places of Worship":[
            'cemetery',
            'church',
            'hindu_temple',
            'mosque',
            'synagogue'
          ],
          "Public Services":[
            'city_hall',
            'courthouse',
            'embassy',
            'fire_station',
            'library',
            'local_government_office',
            'police',
            'post_office'
          ],
          "Schools":[
            'primary_school',
            'school',
            'secondary_school',
            'university'
          ],
          "Recreation & Entertainment":[
            'amusement_park',
            'aquarium',
            'art_gallery',
            'bowling_alley',
            'campground',
            'casino',
            'movie_rental',
            'movie_theater',
            'museum',
            'night_club',
            'park',
            'rv_park',
            'stadium',
            'tourist_attraction',
            'zoo'
          ],
          "Services":[
            'accounting',
            'atm',
            'car_rental',
            'car_repair',
            'car_wash',
            'electrician',
            'funeral_home',
            'gas_station',
            'insurance_agency',
            'laundry',
            'lawyer',
            'locksmith',
            'lodging',
            'moving_company',
            'painter',
            'parking',
            'plumber',
            'real_estate_agency',
            'roofing_contractor',
            'storage',
            'travel_agency',
          ],
          "Shopping":[
            'bicycle_store',
            'book_store',
            'car_dealer',
            'clothing_store',
            'convenience_store',
            'department_store',
            'drugstore',
            'electronics_store',
            'florist',
            'furniture_store',
            'hardware_store',
            'home_goods_store',
            'jewelry_store',
            'pet_store',
            'shoe_store',
            'shopping_mall',
            'store'
          ],

        },
      };
    },
    watch:{


    },
    computed:{
        showAddCategory() {
			return this.$store.state.addCategory.visible
		},
        amenityCats(){
          return this.$store.state.amenityCats.map(x => {
            x.options = Object.entries(x.options).map( ([label,value]) => ({label,value}))
            return x
          })
		},
        instance(){
          return this.$store.state.instance
        },
        appData(){
          return this.$store.state.appData
        },
        autoCompletedText(){
          let result = 'No Data'
          if (this.processingGoogle) result = 'processing'
          else if (this.dataSource.length) 'Found ' + this.dataSource.length

        },
        getTitle(){
		  if (this.drawerTitle) return `Add ${this.drawerTitle}`
		  return ''

        },
        visible(){
          return Boolean(this.newDialog)
        },
        newDialog(){
           return this.$store.state.addAmenityDialog
        }
    },
    methods: {
      req:msg=>({required:true,message:msg}),
      selectedImg(url){
        console.log('SELECTED IMAGE',url)
        this.category.icon = url.url
      },
      updateCatIcon(data){
        console.log('SOME DATAAA here ->', data)
        this.category.icon = data
	  },
      googleCatChange(e){
        console.log('EEEEEEEEEEEEEE', e)
		let cat = this.amenityCats.find(x => x.label === e)
		this.chosenGCat = JSON.parse(JSON.stringify(cat))
		console.log("CHOSENGCAT", this.chosenGCat)
	  },
	  selectedCategories(e){
		  console.log('SELECTED FIELDS',e)
		  let cat = {
			  value: e
		  }
		  this.chosenGCat = JSON.parse(JSON.stringify(cat))
		  console.log("CHOSENGCAT",this.chosenGCat)

	  },
      selectIcon(){
        this.$store.commit('MEDIA_SELECT',{callback:this.selectedIcon,type:'images'})
      },
      iconLibrary(){
        this.$store.commit('LIBRARY_SELECT',{callback:this.selectedIcon,type:'icons'})
      },
      selectedIcon(item){
        if (typeof item === 'string') item = {url:item}
        this.category.icon = item.url
        console.log('URLLLLL',item)
      },
      submitNew(){
        this.$refs.category.validate(valid => {
          if (valid) {
            let self = this


            let data = this.category
            data = this.chosenGCat
            data.label = this.category.label
            data.description = this.category.description
            data.icon = this.category.icon
            self.loading = true
            this.$api.post(`/amenities/${this.instance.id}/${this.appData.id}/`, data).then( ({data}) => {
              console.log('DATA FROM NEW CATEGORY', data)
              self.$emit('categoryData',data)
              self.onClose({type:'new',data})
              self.placeChosen = false
              self.location = {}
              self.category.label = ''
              self.category.icon = markerIcon
              self.chosenGoogleCat = null
              self.chosenGCat = {}
              self.loading = false
            }).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					self.$message.error(self.$err(err))
				}
			})
          } else {
            console.log('error submit!!')
            return false;
          }
        })
        // let self = this
        // this.loading = true


        // let data = this.category
        // data = this.chosenGCat
        // data.label = this.category.label
        // data.icon = this.category.icon

        // if (!data || (data && !data.label)) {
        // this.loading = false
        // return this.$message.error('Fields Missing')
        // }
        // this.$api.post(`/amenities/${this.instance.id}/${this.appData.id}/`, data).then( ({data}) => {
        //     this.loading = false
        //     self.$emit('categoryData',data)
        //     self.onClose()
        // })
        // this.placeChosen = false
        // this.location = {}
        // this.loading = false
        // this.category.label = ''
        // this.category.icon = markerIcon
        // this.chosenGoogleCat = null
        // this.chosenGCat = {}


      },
      resetPlaceSearch(){
        this.location = {}
        this.placeChosen = false
      },
      onClose({type='',data={}}) {
        console.log('ON CLOSE RAN')
        this.$store.dispatch('closeAmenityDrawers',{type,data})
        this.$store.commit('CLOSE_CATEGORY')
        this.$refs.category.resetFields();
        this.placeChosen = false
        this.location = {}
        this.loading = false
        this.category.label = ''
        this.category.icon = markerIcon
        this.chosenGoogleCat = null
        this.chosenGCat = {}
      },
    },
  };
</script>

<style>
  .certain-category-search-dropdown .ant-select-dropdown-menu-item-group-title {
    color: #666;
    font-weight: bold;
  }

  .certain-category-search-dropdown .ant-select-dropdown-menu-item-group {
    border-bottom: 1px solid #f6f6f6;
  }

  .certain-category-search-dropdown .ant-select-dropdown-menu-item {
    padding-left: 16px;
  }

  .certain-category-search-dropdown .ant-select-dropdown-menu-item.show-all {
    text-align: center;
    cursor: default;
  }

  .certain-category-search-dropdown .ant-select-dropdown-menu {
    max-height: 300px;
  }
  .change-tooltip-color .ant-tooltip-content .ant-tooltip-inner{
      background:#3F3356;
  }
</style>
<style scoped>
  .certain-category-search-wrapper
    >>> .certain-category-search.ant-select-auto-complete
    .ant-input-affix-wrapper
    .ant-input-suffix {
    right: 12px;
  }
  .certain-category-search-wrapper >>> .certain-search-item-count {
    position: absolute;
    color: #999;
    right: 16px;
  }
  .certain-category-search-wrapper
    >>> .certain-category-search.ant-select-focused
    .certain-category-icon {
    color: #108ee9;
  }
  .certain-category-search-wrapper >>> .certain-category-icon {
    color: #6e6e6e;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    font-size: 16px;
  }
  .image-holder-box{
      display: table;
      width: 104px;
      height: 104px;
      text-align: center;
      vertical-align: top;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      cursor: pointer;
  }
  .image-holder-box:hover{
      border-color: #9693E7;
      transition: border-color .3s ease;
  }
  .image-holder-box .content{
    display: table-cell;
    width: 100%;
    height: 100%;
    padding: 8px;
    text-align: center;
    vertical-align: middle;
  }
  .image-holder-box .content .inside{
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50%;
    width: 100px;
    height: 100px;
  }
  .image-selector {
	position: absolute;
	z-index: 1000;
	width: 250px;
	margin-top: 75px;
  }
  .edit-current-marker{
		position: absolute;
		background-color: var(--white);
		top: 0;
		z-index: 100;
        left: 80px;
    }
</style>

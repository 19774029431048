<template>
    <div>
        <bh-loading :show="loading" />
        <h1>Create your amenity categories</h1>
        <div class="mb-4">Bildhive uses Google Data to prepopulate your categories</div>

        <newCategory :title="'Add New Category'" @categoryData="updateCategories" />
        <draggable :list="amenityCategories" @end="endMove" :delay-on-touch-only="true" :delay="100">
            <div v-for="(cat,catI) in amenityCategories" :key="cat+catI">
                <div class="category-buttons dF aC px-3" style="gap: 1.25em;">
                    <div>
                        <img :src="cat.icon" style="width: 20px; height: 20px;"/>
                    </div>
                    <div class="f1">
                        {{cat.name}}
                    </div>
                    <div>
                        <template v-if="!cat.processing">
                            <svg @click="editCat(cat)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2 mr-2 delete-icon"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
                            <svg @click="deleteCat(cat)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2 delete-icon"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                        </template>
                        <template v-else>
                            <a-icon type="loading" />
                        </template>
                    </div>
                </div>
            </div>
        </draggable>
        <a-row class="category-buttons" style="cursor: pointer;">
            <a-col :span="24" @click="addCategory">
                <a-icon type="plus" class="mr-3" style="font-size: 16px;"/>ADD NEW CATEGORY
            </a-col>
        </a-row>

        <portal to="destination">
            <!-- <a-button size="large" class="text-black mx-4" type='link' @click="skip">SKIP</a-button> -->
            <a-button size="large" type='purple' class="back-button" @click="next" >DONE</a-button>
        </portal>

    </div>
</template>

<script>
import * as maptalks from 'maptalks'
import MarkerSelector from '@/components/amenities/MarkerSelector'
import newCategory from './newCategory'
import { gsap } from "gsap";
import draggable from 'vuedraggable'
import bhLoading from 'bh-mod/components/common/Loading'

export default {
    props:{
        value:Number,
    },
    components:{MarkerSelector,newCategory,draggable,bhLoading},
    data:() => ({
        loading:false,
        currentStep:1,
        // amenityCategories:[],
        amenityOBJ:{
          name:'',
          lat:null,
          lng:null,
          logo:'https://bildhive.nyc3.digitaloceanspaces.com/96c8782107aa4021b5bb02f4f50c2aa6.png',
          theme:'',
          categories:[

          ]
        },
    }),
    watch:{
        currentStep(val){
            return this.$emit('input',val)
        },
    },
    computed: {
        instance(){
            return this.$store.state.instance
        },
        theApp(){
            return this.$store.state.theApp
        },
        appData(){
          return this.$store.state.appData
        },
        amenityCategories(){
            console.log('AMENITY CATEGORIES RAN AGAIN FROM TEST', this.$store.state.amenityCategories)
            let {__pinnedMarkers,...restCat} = this.$store.state.amenityCategories
            return [...Object.values(restCat)]
            // .sort( (a,b) => {
            // if (a.order > b.order) return 1
            // else return -1
            // })]
            // return this.$store.state.amenityCategories
        },
    },
    methods:{
        endMove(e) {
            console.log('reorder categories', e)
            console.log('categories', this.amenityCategories)
            this.loading = true
            this.$store.commit('REORDER_CATEGORY', {oldIndex:e.oldIndex, newIndex:e.newIndex})
            this.loading = false
        },
        updateCategories(data){
            console.log('DATA FROM UPDATE CATEGORIES', data)
            // this.amenityCategories.push(data)
            console.log('THIS AMENITY CATEGORIES FROM UPDATE CATEGORIES', this.amenityCategories)
            console.log('AMENITY CATEGORIES', this.amenityCategories)
        },
        editCat(cat){
            console.log('EDIT CAT CLICKED', cat)
            let findCat = this.amenityCategories.find(x => x.id === cat.id)
            this.$store.commit('plotCategory',findCat)
            this.$store.dispatch('editCategory', this.$store.state.amenityCategories[this.$store.state.plotCategory])
        },
        deleteCat(cat){
            console.log('DELETE CAT', cat)
            console.log('INSTANCE ID', this.instance.id)
            console.log('APP DATA ID', this.appData.id)
            let self = this
            let obj = {}
            let deleteURL = `/amenities/${this.instance.id}/${this.appData.id}/${cat.id}/all`
            this.$api.delete(deleteURL).then( ({data}) => {
                for (var i=0;i<this.amenityCategories.length;i++){
                    if (this.amenityCategories[i].id == cat.id){
                        obj = this.amenityCategories[i]
                    }
                }
                this.onClose({type:'delete',data:obj})
            }).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
        },
        addCategory(){
            console.log('clicked')
            this.$store.commit('OPEN_CATEGORY')
        },
        selectedMarkerIcon(url){
            this.amenityOBJ.logo = url.url
        },
        updateMarkerIcon(data){
            this.amenityOBJ.logo = data
        },
        skip(){
            return this.$emit('next')
        },
        next(){
            return this.$emit('done', this.amenityOBJ )
        },
        onClose({type='',data={}}) {
		  this.$store.dispatch('closeAmenityDrawers',{type,data})
		},
    },
    created(){

    }
}
</script>

<style scoped>
    .category-buttons {
        border: 1px solid rgba(112,112,112,0.16);
        height: 50px;
        border-radius: 5px;
        text-align: center;
        line-height: 50px;
        font-weight: bold;
        user-select: none;
    }
    .delete-icon {
		cursor: pointer;
		color: rgba(0, 0, 0, 0.65);
		transition:color .1s ease-in;
	}
	.delete-icon:hover {
		color: #9693E7;
		transition:color .1s ease-in;
	}

</style>
<style lang="scss">
    .edit-current-marker{
        i{
            pointer-events: none;
        }
        svg{
            pointer-events: none;
        }
        cursor: pointer;
        position: absolute;
        z-index: 100;
        left: 80px;
        top: 0;
        right: 0;
        background: white;
        padding: 10px;
        transform: translate(50%,-50%);
        border-radius: 50%;
        box-shadow: -1px 1px 4px rgba(0,0,0,0.3);
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover{
            background:white;
            color:var(--med-gray);
        }
    }
</style>
